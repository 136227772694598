import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpServiceBase} from "../classes/HttpServiceBase";
import {effect, Injectable, signal} from "@angular/core";
import {NearbyLab} from "../interfaces/Lab";
import {ToastrService} from "ngx-toastr";
import {SessionStorageService} from "ngx-webstorage";
import {JsonResponse} from "../interfaces/JsonResponse.interface";
import {intersectionBy, isEqual, isNil, omitBy} from "lodash";
import {Exam} from "../interfaces/Exam";

@Injectable({providedIn: 'root'})
export class LabService extends HttpServiceBase {
    protected override baseUrl: string = "/labs";

    public selectedLab = signal<(Partial<NearbyLab> & { id: string }) | null>(
        null
    );
    public selectedTests = signal<(Partial<Exam> & { id: string })[] | null>(null);
    public rescheduleId = signal<string | null>(null);
    public rescheduleReasonId = signal<string | null>(null);
    public rescheduleReasonComment = signal<string | null>(null);

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private session: SessionStorageService
    ) {
        super();
        this.assign(this);
        const storedLab = this.session.retrieve('selectedLab');
        if (storedLab) {
            this.selectedLab.set(storedLab);
        }
        const storedTests = this.session.retrieve('selectedTests');
        if (storedTests) {
            this.selectedTests.set(storedTests);
        }

        const storedRescheduleReasonId = this.session.retrieve('rescheduleReasonId');
        if (storedRescheduleReasonId) {
            this.rescheduleReasonId.set(storedRescheduleReasonId);
        }

        const storedRescheduleId = this.session.retrieve('rescheduleId');
        if (storedRescheduleId) {
            this.rescheduleId.set(storedRescheduleId);
        }

        const storedRescheduleReasonComment = this.session.retrieve('rescheduleReasonComment');
        if (storedRescheduleReasonComment) {
            this.rescheduleReasonComment.set(storedRescheduleReasonComment);
        }

        effect(() => {
            if (!this.selectedLab()) {
                return;
            }
            if (!isEqual(this.selectedLab(), this.session.retrieve('selectedLab'))) {
                this.session.store('selectedLab', this.selectedLab());
            }
        });

        effect(() => {
            if (!this.selectedTests()?.length) {
                return;
            }
            if (
                intersectionBy(
                    this.selectedTests() ?? [],
                    this.session.retrieve('selectedTests') ?? [],
                    'id'
                ).length !== this.selectedTests()?.length
            ) {
                this.session.store('selectedTests', this.selectedTests());
            }
        });

        effect(() => {
            if (!this.rescheduleId()) {
                return;
            }
            if (this.rescheduleId() !== this.session.retrieve('rescheduleId')) {
                this.session.store('rescheduleId', this.rescheduleId());
            }
        });

        effect(() => {
            if (!this.rescheduleReasonId()) {
                return;
            }
            if (this.rescheduleReasonId() !== this.session.retrieve('rescheduleReasonId')) {
                this.session.store('rescheduleReasonId', this.rescheduleReasonId());
            }
        });

        effect(() => {
            if (!this.rescheduleReasonComment()) {
                return;
            }
            if (this.rescheduleReasonComment() !== this.session.retrieve('rescheduleReasonComment')) {
                this.session.store('rescheduleReasonComment', this.rescheduleReasonComment());
            }
        });

    }

    /**
     * Get labs by coordinates, optionally pass a bounding rect to filter results
      * @param coords
     * @param bounds
     * @param bounds.ne - North East coordinates [LNG, LAT]
     * @param bounds.sw - South West coordinates [LNG, LAT]
     * @param exams     - Array of exam ids to filter labs by
     */
    public getLabsByCoordinates(coords: { latitude: number; longitude: number }, bounds?: {ne?: number[], sw?: number[]}, exams?: string[]): Observable<any> {
        return this._http.post(this.baseUrl + '/find', omitBy({
            latitude: coords.latitude,
            longitude: coords.longitude,
            swBounds: bounds?.sw,
            neBounds: bounds?.ne,
            exams
        }, isNil))
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public getAvailableSlots(date: Date | string, labId: string): Observable<any> {
        return this._http.post(this.baseUrl + '/slots', {date, labId})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public bookAppointment(exams: {
        id: string;
        quantity: number
    }[], labId: string, date: Date | string, time: string, privately?: boolean, ssnRequestId?: string, ssn?: string): Observable<any> {
        return this._http.post(this.baseUrl + '/book-appointment', omitBy({
            exams,
            labId,
            date,
            time,
            privately,
            ssnRequestId,
            ssn
        }, isNil))
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public rescheduleAppointment(appointmentId: string, date: Date | string, time: string, rescheduleReasonId?: string, rescheduleReasonText?: string): Observable<any> {
        return this._http.post(this.baseUrl + '/reschedule-appointment', omitBy({
            appointmentId,
            date,
            time,
            rescheduleReasonId,
            rescheduleReasonText
        }, isNil))
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public cancelAppointment(appointmentId: string, cancellationReasonId?: string, cancelReasonText?: string): Observable<any> {
        return this._http.post(this.baseUrl + '/cancel-appointment', {
            appointmentId,
            cancellationReasonId,
            cancelReasonText
        })
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public retrieveAppointmentByHash(hash: string): Observable<any> {
        return this._http.post(this.baseUrl + '/hash-appointment', {hash})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public retrieveSsnReqByHash(hash: string): Observable<any> {
        return this._http.post(this.baseUrl + '/hash-request', {hash})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public retrieveActiveAppointment(exams?: string[]): Observable<any> {
        return this._http.post(this.baseUrl + '/active-appointment', {exams})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public retrieveCurrentUserAppointments(page: number = 0, limit?: number): Observable<JsonResponse> {
        return this._http.post(this.baseUrl + '/my-appointments', {skip: page * (limit ?? 0), limit})
            .pipe(map((response) => response as JsonResponse),
                catchError(this.handleError.bind(this)));
    }


    // HELPERS

    public clearStoredData(onlySession: boolean = false) {
        if (!onlySession) {

            this.selectedLab.set(null);
            this.selectedTests.set(null);
            this.rescheduleId.set(null);
            this.rescheduleReasonId.set(null);
            this.rescheduleReasonComment.set(null);

        }
        this.session.clear('selectedLab');
        this.session.clear('selectedTests');
        this.session.clear('rescheduleId');
        this.session.clear('rescheduleReasonId');
        this.session.clear('rescheduleReasonComment');
    }

    public clearMapData() {
        this.session.clear("coords");
        this.session.clear("zoom");
        this.session.clear("user_pin_coords");
    }

}
